/*
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-22 13:52:27
 * @LastEditTime: 2021-01-25 17:16:49
 * @有问题找百度，实在不行就删库
 */
import { get, post } from "./request";


//获取state
export const GetState = (params) => get("/wechat/getState", params);

//获取助力页信息
export const GetUserInfo = (params) => get("/wechat/getUserInfo", params);

//获取问答页信息
export const GetUserQuestionInfo = (params) => get("/question/getUserInfo", params);

//提交问卷
export const Submit = (params) => post("/question/submit", params);


//助力
export const Help = (params) => get("/wechat/help", params);

//获取js-sdk相关
export const Jsconfig = (params) => get("/wechat/jsconfig", params);

//获取验证码
export const GetCode = (params) => get("/bound/getCode", params);

//绑定微信接口
export const Wechat = (params) => get("/bound/wechat", params);
