<template>
  <section v-if="dialogShow" :class="{ equipment: !isPhone }">
    <!-- 非法请求 -->
    <div class="dialog-input" @touchmove.prevent v-if="type == 0">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <img src="../../assets/images/input-back.png" alt="" />
          <div class="text">请前往小牛翻译云平台PC端参与此活动</div>
        </div>
      </div>
    </div>
    <!-- 提示绑定 -->
    <div class="dialog-input" @touchmove.prevent v-if="type == 1">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleCancel" />
          <img src="../../assets/images/input-back.png" alt="" />
          <div class="text">请先绑定小牛翻译云平台账号</div>
          <van-row class="row-btn">
            <van-col span="14"
              ><img
                src="../../assets/images/no-account.png"
                alt=""
                class="left"
                style="width: 85%"
                @click="handleRegister"
            /></van-col>
            <van-col span="10"
              ><img
                src="../../assets/images/go-bind.png"
                alt=""
                class="right"
                style="width: 85%"
                @click="jumpToBind"
            /></van-col>
          </van-row>
        </div>
      </div>
    </div>
    <!-- 账号填写 -->
    <div class="dialog-input" @touchmove.prevent v-if="type == 2">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleCancel" />
          <img src="../../assets/images/input-back.png" alt="" />
          <div class="form-info">
            <van-field
              v-model="accountForm.account"
              center
              placeholder="请输入平台账号"
              :class="accountError ? 'danger' : ''"
              @blur="accountRule"
            >
            </van-field>
            <span class="account">{{ accountMsg }}</span>
            <van-field
              v-model="accountForm.code"
              center
              placeholder="请输入验证码"
              :class="codeError ? 'danger' : ''"
              @blur="codeRule"
            >
              <template #button>
                <van-button size="small" @click="sendCode" v-if="myCode"
                  >发送验证码</van-button
                >
                <van-button size="small" v-else>{{ btntxt }}</van-button>
              </template>
            </van-field>
            <span class="account-code">{{ codeMsg }}</span>
          </div>

          <van-row class="row-btn input-row">
            <van-col span="12"
              ><img
                src="../../assets/images/cancel-btn.png"
                alt=""
                class="left"
                style="width: 75%"
                @click="handleCancel"
            /></van-col>
            <van-col span="12"
              ><img
                src="../../assets/images/bind-btn.png"
                alt=""
                class="right"
                @click="handleBind"
            /></van-col>
          </van-row>
        </div>
      </div>
    </div>
    <!-- 绑定成功 -->
    <div class="dialog-input back_two" @touchmove.prevent v-if="type == 3">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleCancelHelp" />
          <img src="../../assets/images/input-back-2.png" alt="" />
          <img
            src="../../assets/images/bind-success.png"
            alt=""
            class="success-btn"
          />
          <div class="text">绑定成功</div>
          <van-row class="row-btn">
            <van-col span="24"
              ><img
                src="../../assets/images/immediately-btn.png"
                alt=""
                style="width: 65%"
                @click="handleMyHelp"
            /></van-col>
          </van-row>
        </div>
      </div>
    </div>
    <!-- 未注册 -->
    <div class="dialog-input back_two" @touchmove.prevent v-if="type == 4">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleCancel" />
          <img src="../../assets/images/input-back-2.png" alt="" />
          <img
            src="../../assets/images/bind-error.png"
            alt=""
            class="success-btn"
          />
          <div class="text">该账号未注册小牛翻译云平台账号</div>
          <van-row class="row-btn">
            <van-col span="12"
              ><img
                src="../../assets/images/cancel-btn.png"
                alt=""
                class="left"
                style="width: 70%"
                @click="handleCancel"
            /></van-col>
            <van-col span="12"
              ><img
                src="../../assets/images/register-btn.png"
                alt=""
                class="right"
                style="width: 70%"
                @click="handleRegister"
            /></van-col>
          </van-row>
        </div>
      </div>
    </div>
    <!-- 调查页绑定成功 -->
    <div class="dialog-input back_two" @touchmove.prevent v-if="type == 5">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleSurvey" />
          <img src="../../assets/images/input-back-2.png" alt="" />
          <img
            src="../../assets/images/bind-success.png"
            alt=""
            class="success-btn"
          />
          <div class="text">绑定成功</div>
          <van-row class="row-btn">
            <van-col span="24"
              ><img
                src="../../assets/images/immediately-btn.png"
                alt=""
                style="width: 65%"
                @click="handleSurvey"
            /></van-col>
          </van-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetCode, Wechat } from "../../util/api";
import { getUrlParam } from "../../util/util";
export default {
  props: {
    type: Number,
    dialogShow: {
      type: Boolean,
      default: false,
    },
    state: String,
  },
  data() {
    return {
      accountMsg: "",
      codeMsg: "",
      accountError: false,
      codeError: false,
      time: 0,
      btntxt: "",
      myCode: true,
      accountForm: {
        account: "",
        code: "",
      },
      accountReg: /^1[3456789]\d{9}$/,
      emailReg: /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+.)+[A-Za-z]{2,6}$/,
      niutrans: "",
      isPhone: true,
    };
  },
  mounted() {
    this.isPhone = this.$store.getters["userInfo/isPhone"];
  },
  methods: {
    handleSurvey() {
      this.$emit("takeSurvey");
    },
    jumpToBind() {
      this.$emit("changeType", 2);
    },
    handleMyHelp() {
      window.location.href = this.$urls + "/zhuli";
    },
    handleRegister() {
      window.location.href =
        "https://niutrans.com/register?from=h5?niutrans=" +
        getUrlParam("niutrans");
    },
    handleBind() {
      if (this.accountForm.account && this.accountForm.code) {
        if (!this.accountError && !this.codeError) {
          let obj = {
            state: this.$store.getters["userInfo/stateVal"],
            account: this.accountForm.account,
            type: this.accountReg.test(this.accountForm.account) ? 1 : 0,
            authcode: this.accountForm.code,
          };
          Wechat(obj).then((res) => {
            if (res.data.error_code == 0) {
              this.niutrans = res.data.niutrans;
              this.$emit("changeType", 3);
            } else if (res.data.error_code == 10005) {
              this.codeMsg = "*验证码不正确";
              this.codeError = true;
            } else if (res.data.error_code == 10006) {
              this.$emit("changeType", 4);
            } else {
              this.$toast(res.data.error_msg);
            }
          });
        }
      } else {
        this.accountRule();
        this.codeRule();
      }
    },
    accountRule() {
      if (!this.accountForm.account) {
        this.accountMsg = "*请填写账号";
        this.accountError = true;
      } else if (
        !this.accountReg.test(this.accountForm.account) &&
        !this.emailReg.test(this.accountForm.account)
      ) {
        this.accountMsg = "*账号格式不正确";
        this.accountError = true;
      } else {
        this.accountMsg = "";
        this.accountError = false;
      }
    },
    codeRule() {
      if (!this.accountForm.code) {
        this.codeMsg = "*请填写验证码";
        this.codeError = true;
      } else {
        this.codeMsg = "";
        this.codeError = false;
      }
    },
    handleCancel() {
      this.$emit("changeShow", false);
    },
    handleCancelHelp() {
      this.$emit("changeShow", false);
      this.$emit("helpNutrans", this.niutrans);
    },
    //发送验证码
    sendCode() {
      if (this.accountForm.account) {
        this.accountRule();
        if (!this.accountError) {
          let obj = {
            state: this.$store.getters["userInfo/stateVal"],
            account: this.accountForm.account,
            type: this.accountReg.test(this.accountForm.account) ? 1 : 0,
          };
          GetCode(obj).then((res) => {
            if (res.data.error_code == 0) {
              this.$toast("发送成功");
              this.time = 60;
              this.myCode = false;
              this.timer();
            } else {
              this.$toast(res.data.error_msg);
            }
          });
        }
      } else {
        this.accountMsg = "*请填写账号";
        this.accountError = true;
      }
    },
    //60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新发送";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.myCode = true;
        clearInterval(this.timer);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.equipment {
  .dialog-input {
    max-width: 640PX !important;
    margin: 0 auto !important;
  }
}
.dialog-input {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .dialog-wrap {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .dialog-view {
      position: relative;
      i {
        font-size: 22px;
        position: absolute;
        top: 35px;
        right: -25px;
      }
      img {
        width: 100%;
      }
      .text {
        width: 70%;
        line-height: 1.5;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%);
        font-size: 14px;
        font-weight: 600;
      }
      .form-info {
        width: 100%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%);
        .danger {
          border: 1PX solid #e1001e !important;
        }
        .van-field {
          margin: 0 auto;
          width: 85%;
          height: 26px;
          border: 1PX solid #bbb;
          background-color: transparent;
          padding: 0 5px;
          border-radius: 2px;
          margin-bottom: 25px;
        }
        .account,
        .account-code {
          color: red;
          position: absolute;
          left: 20px;
        }
        .account {
          top: 26%;
        }
        .account-code {
          top: 76%;
        }
        .van-button--default {
          border: none;
          background-color: transparent;
          font-weight: 600;
        }
      }
      .row-btn {
        width: 100%;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
        img {
          width: 80%;
        }
        .left {
          float: right;
          margin-right: 5px;
        }
        .right {
          float: left;
          margin-left: 5px;
        }
      }
      .input-row {
        bottom: 5px !important;
      }
    }
  }
}
.back_two {
  i {
    top: -20px !important;
    right: -25px;
  }
  .success-btn {
    width: 20% !important;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
  }
  .text {
    width: 80% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .row-btn {
    bottom: 20px !important;
  }
}
</style>
