/*
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-22 13:23:33
 * @LastEditTime: 2021-01-27 19:29:52
 * @有问题找百度，实在不行就删库
 */
import { GetState, GetUserInfo, GetUserQuestionInfo } from './api';
import store from '../store/index'
import { urls } from './request'

export function getWxPublicInfo(name, callback) {
  const APPID = "wxb7f9d861e7084931";
  GetState({ appid: APPID }).then(res => {
    store.commit("userInfo/SET_STATE", res.data.state);
    const code = getUrlParam('code');
    const local = window.location.href;
    if (code == null || code === '') {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=' + res.data.state + '#wechat_redirect'
    } else {
      const state = res.data.state;
      const niutrans = getUrlParam('niutrans');
      if (name == 'question') {
        let obj = {
          code: code,
          state: state,
        }
        GetUserQuestionInfo(obj).then(res => {
          if (res.data.error_code == 10002) {
            window.location.href = urls + "/zhuli/questionnaire";
          } else {
            callback(res.data)

          }

        })
      } else {
        let obj = {
          code: code,
          state: state,
          niutrans: niutrans,
        }
        GetUserInfo(obj).then(res => {
          if (res.data.error_code == 10002) {
            window.location.href = urls + "/zhuli?niutrans=" + niutrans;
          } else {
            callback(res.data)
          }

        })
      }

    }
  })
}

export function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}
